<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentGroups.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="newGroup()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="filter.level"
                        :options="levels"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary">{{
                  $t("common.filter")
                }}
              </b-button>
            </b-form>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("studentGroups.list.name") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.level") }}
                </th>
                <th class="text-left">
                  {{ $t("studentGroups.list.year") }}
                </th>
                <th class="text-left">Воспитатель</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in groups" :key="item.id">
                <td>{{ item.groupName }}</td>
                <td>{{ dictionariesService.preschoolLevelName(item.level) }}</td>
                <td>{{ item.educationYearString }}</td>
                <td>{{ item.kindergartenerName }}</td>
                <td>
                  <b-button
                      type="submit"
                      @click="editGroup(item)"
                      variant="primary"
                  >
                    {{ $t("common.edit") }}
                  </b-button>
                  <router-link :to="{ name: 'PreschoolAdminStudents', params: { id: item.id }}" class="btn btn-success">
                    Воспитанники
                  </router-link>   
                  <router-link :to="{ name: 'PreschoolGroupInfo', params: { id: item.id }}" class="btn btn-success" style="margin-left: 10px">
                    Детали
                  </router-link>
                  <!--                    <b-button @click="openGroupInfo(item)" variant="primary">
                                        Детали
                                      </b-button>
                                      <b-button @click="deleteGroup(item)" variant="primary">
                                        Удалить
                                      </b-button>-->
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal id="modal-1" ref="preschool-groups-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.name") }}
                    </template>
                    <b-form-input
                        v-model="form.groupName"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="form.level"
                        :options="levels"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="form.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      Воспитатель
                    </template>
                    <b-form-select
                        v-model="form.kindergartenerId"
                        :options="kindergarteners"
                        required
                    ></b-form-select>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "PreschoolAdminGroups",
  computed: {
    dictionariesService() {
      return DictionariesService
    }
  },
  components: {
    KTPortlet
  },
  data() {
    return {
      filter:{
        educationYear: 2023,
        level: 1
      },
      preschoolId: null,
      groups: null,
      dialog: true,
      form: {
        groupName: "",
        level: "",
        educationYear: "",
        shift: "",
        callScheduleId: 0
      },
      show: true,
      levels: DictionariesService.preschoolLevels(),
      educationYears: [{text: "2023-2024", value: 2023}],
      callSchedules: [],
      kindergarteners: []
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      this.form.preschoolId = this.$route.params.id;

      ApiService.postSecured("preschoolGroups/save", this.form)
          .then(function () {
            $this.$refs["preschool-groups-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["preschool-groups-modal"].hide();
    },
    reloadData() {
      this.filter.preschoolId = this.$route.params.id;
      let $this = this;
      ApiService.postSecured("preschoolGroups/list", this.filter).then(({data}) => {
        $this.groups = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
      
      
      ApiService.querySecured("dictionaries/callSchedules")
          .then(function (data) {
            $this.callSchedules = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/kindergartenersLoggedUserSchool", {}).then(({data}) => {
        $this.kindergarteners = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {};
    },
    newGroup() {
      this.form = {};
      this.$refs["preschool-groups-modal"].show();
    },
    editGroup(group) {
      this.form = group;
      this.$refs["preschool-groups-modal"].show();
    },
    openStudents(group) {
      this.$router.push({name: "students", params: {id: group.id}});
    },
    openGroupInfo(group) {
      this.$router.push({name: "groupInfo", params: {groupId: group.id}});
    },
    deleteGroup(group) {
      if(confirm("Удалить?")){
        let $this = this;
        ApiService.postSecured("admin/deleteGroup", {groupId:group.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }      
    }
  }
};
</script>
